<template>
  <div>
    <div class="fixTop">
      <div
        style="
          display: flex;
          justify-content: space-between;
          width: 100%;
          align-items: center;
        "
      >
        <div style="display: flex; align-items: center">
          <img src="../assets/images/index/logo.png" alt="" class="logo" />
          <span
            class="cursor"
            style="margin-right: 48px"
            @click="$router.push('/')"
          >
            HOME
          </span>
          <span class="cursor" @click="$router.push('/aboutus/blog')">
            BLOG
          </span>
        </div>
        <img
          cl
          class="cursor joinus"
          @click="$router.push('/form')"
          src="../assets/images/index/titlejoin.png"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavButton',
  props: ['currentNav'],
  data() {
    return {}
  },
  mounted() {},
  methods: {},
  computed: {},
}
</script>

<style lang="less" scoped>
.fixTop {
  display: flex;
  align-items: center;
  height: 125px;
  background: fff;
  width: 100%;
  box-sizing: border-box;
  padding: 0 247px;

  font-size: 20px;
}
.logo {
  height: 50px;
  margin-right: 65px;
}
.joinus {
  width: 172px;
  height: 34px;
}
</style>
