import server from './server'

export const getAboutList = (module, curpage, page_size = 6) => {
  return server.requestMutiGet('/stores/tdd/aprtp_home/get_articles', {
    module,
    curpage,
    page_size,
  })
}

export const getAboutDetail = (guid) => {
  return server.requestMutiGet('/stores//tdd/aprtp_home/get_blog_detail', {
    guid,
  })
}
