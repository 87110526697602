import server from './server'
import qs from 'qs'
export const getBanners = () => {
  return server.requestMutiGet('/stores/tdd/stox_xxb_banner/lists')
}

export const sumbitForm = (data) => {
  return server.requestPost(
    'stores/tdd/aprtp_home/submit_join_form',
    qs.stringify(data)
  )
}
