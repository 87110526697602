import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/index.vue'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
// push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch((err) => err)
}
// replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch((err) => err)
}
const routes = [
  {
    path: '/',
    name: 'index',
    meta: {
      keepAlive: true,
    },
    component: () => import('@/views/index.vue'),
  },
  {
    path: '/aboutus',
    name: 'aboutus',
    component: () => import('@/views/AboutUs/index.vue'),
    children: [
      {
        path: '/aboutus/news',
        name: 'news',
        meta: {
          title: 'News & Resources',
        },
        component: () => import('@/views/AboutUs/news'),
      },

      {
        path: '/aboutus/blog',
        name: 'blog',
        meta: {
          title: 'blog',
        },
        component: () => import('@/views/AboutUs/blog'),
      },
      {
        path: '/aboutus/news/Detail/:id',
        name: 'newsDetail',
        meta: {
          title: 'news',
        },
        component: () => import('@/views/AboutUs/newsDetail'),
      },
      {
        path: '/aboutus/blog/Detail/:id',
        name: 'blogDetail',
        meta: {
          title: 'blog',
        },
        component: () => import('@/views/AboutUs/blogDetail'),
      },
      {
        path: '/event',
        name: 'event',
        meta: {
          title: 'event',
        },
        component: () => import('@/views/events/events.vue'),
      },
    ],
  },
  {
    path: '/form',
    name: 'form',
    meta: {
      title: 'join us',
    },
    component: () => import('@/views/form.vue'),
  },
  {
    path: '/event_h5',
    name: 'event_h5',
    meta: {
      title: 'event_h5',
    },
    component: () => import('@/views/events/event_h5.vue'),
  },
]

const router = new VueRouter({
  mode: 'hash',

  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    console.log(to, from, savedPosition)
    return { x: 0, y: 0 }
  },
})

export default router
