<template>
  <div class="bottom">
    <div class="banxin">
      <div class="top">
        <div class="l">
          <div>
            <img class="logo" src="../assets/images/index/logo2.png" alt="" />
          </div>
        </div>
        <div class="r">
          <div style="display: flex; justify-content: space-between">
            <div class="iconhref cursor" style="margin-right: 100px">
              <a href="https://twitter.com/APRWATP" target="_blank">
                <img
                  src="../assets/images/index/icon1.png"
                  alt=""
                  class="icon"
                />
                <span> Twitter </span>
              </a>
            </div>
            <div class="iconhref cursor" style="margin-right: 100px">
              <a
                href="https://www.linkedin.com/company/ap-r-tp/about"
                target="_blank"
              >
                <img
                  src="../assets/images/index/icon3.png"
                  alt=""
                  class="icon"
                />
                <span> LinkedIn </span>
              </a>
            </div>
            <div class="iconhref cursor">
              <a
                :href="href + 'file/AP®TP Website Terms of Use.pdf'"
                target="_blank"
              >
                <img
                  src="../assets/images/index/icon2.png"
                  alt=""
                  class="icon"
                />
                <span> Terms of Use</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="copy">Copyright © AP®TP</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      href: window.location.href.split('#')[0],
    }
  },
}
</script>

<style lang="less" scoped>
.bottom {
  width: 100%;
  position: absolute;
  bottom: 0;
  color: #fff;
  font-size: 20px;
  padding-top: 84px;
  height: 322px;
  background: #048777;
  .banxin {
    margin: 0 auto;
    width: 80%;
    // background: #fff;
  }
}
.top {
  padding-bottom: 100px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #fff;
  .l {
    width: 453px;
  }
  .logo {
    width: 264px;
    margin-bottom: 50px;
  }
  .r {
    line-height: 50px;
    .iconhref {
      a {
        color: #fff;
      }
      display: flex;

      align-items: center;
      .icon {
        width: 21px;
        margin-right: 10px;
      }
    }
  }
}
.copy {
  line-height: 100px;
}
</style>
