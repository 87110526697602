<template>
  <div class="bg">
    <Header :currentNav="0"></Header>

    <div style="width: 100%; overflow: hidden">
      <div class="swiper-container2">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="item in banners" :key="item.guid">
            <img :src="item.image_url" alt="" width="100%" class="cursor" />
          </div>
        </div>
      </div>
      <!-- <img src="../assets/images/index/home1.png" alt="" width="100%" /> -->
      <div class="banxin whoarewe">
        <div class="l">
          <h3>Who are we</h3>
          <div class="being">
            <p>
              Being a pioneering initiative to serve as a collaborative
              ecosystem where like-minded RWA tokenization players and
              traditional institutions can come together to exchange information
              and ideas, establish industry standards, build technology
              infrastructure, and develop real business cases.
            </p>
            <p>
              亚太RWA平台（AP®TP）是一个开创性的组织，旨在创建一个合作生态系统，各方面认同的实物资产（RWA）通证化参与者和传统机构可以交流信息观点，建立行业标准，构建技术基础设施，并开展真实的商业案例。
            </p>
          </div>
          <img src="../assets/images/index/who.png" alt="" class="whoimg" />
        </div>

        <div class="r">
          <div class="label">
            <!-- <div class="row">
              <div class="l">
                <div>Technology Platform</div>
                <div>技术平台</div>
              </div>
              <a href="https://www.chainup.com" target="_blank">
                <img
                  src="../assets/images/icons/row11.png"
                  alt=""
                  class="row11"
              /></a>
              <a href=" https://www.sgtox.com" target="_blank">
                <img
                  src="../assets/images/icons/row12.png"
                  alt=""
                  class="row12"
              /></a>
              <a href=" https://sg.alibabacloud.com" target="_blank">
                <img
                  src="../assets/images/icons/row13.png"
                  alt=""
                  class="row13"
              /></a>
              <a href="javascript:;">
                <img
                  src="../assets/images/icons/row14.png"
                  alt=""
                  class="row14"
              /></a>
            </div> -->
            <div class="row">
              <div class="l">
                <div>Banks & Related</div>
                <div>银行及相关机构</div>
              </div>
              <a
                href="https://www.qnb.com/sites/qnb/qnbsingapore/page/en/en-home.html"
                target="_blank"
              >
                <img
                  src="../assets/images/icons/row11.png"
                  alt=""
                  class="row11"
              /></a>
              <a href="https://www.cmbi.com.hk/zh-CN/" target="_blank">
                <img
                  src="../assets/images/icons/row12.png"
                  alt=""
                  class="row12"
              /></a>
              <a
                href="https://news.spdb.com.cn/overseas_institutions/sgp_spdbbank/index.shtml"
                target="_blank"
              >
                <img
                  src="../assets/images/icons/row13.png"
                  alt=""
                  class="row13"
              /></a>
            </div>

            <div class="row">
              <div class="l">
                <div>Crypto Exchange</div>
                <div>加密资产交易所</div>
              </div>
              <a href="https://www.bybit.com/en/" target="_blank">
                <img
                  src="../assets/images/icons/row21.png"
                  alt=""
                  class="row21"
              /></a>
              <a href="https://www.kucoin.com/" target="_blank">
                <img
                  src="../assets/images/icons/row22.png"
                  alt=""
                  class="row22"
              /></a>
            </div>
            <div class="row">
              <div class="l">
                <div>Tech Infrastructure</div>
                <div>技术基础设施</div>
              </div>
              <a href=" https://dmz.finance/#/" target="_blank">
                <img
                  src="../assets/images/icons/row31.png"
                  alt=""
                  class="row31"
              /></a>
              <a href="https://www.fireblocks.com/" target="_blank">
                <img
                  src="../assets/images/icons/row32.png"
                  alt=""
                  class="row32"
              /></a>
              <a href="https://www.ceffu.com/" target="_blank">
                <img
                  src="../assets/images/icons/row33.png"
                  alt=""
                  class="row33"
              /></a>
              <a href="https://www.certik.com/zh-CN" target="_blank">
                <img
                  src="../assets/images/icons/row34.png"
                  alt=""
                  class="row34"
              /></a>
            </div>
            <div class="row">
              <div class="l">
                <div>Crypto Ecosystem</div>
                <div>加密资产生态圈</div>
              </div>
              <a href="https://www.ambergroup.io/" target="_blank">
                <img
                  src="../assets/images/icons/row41.png"
                  alt=""
                  class="row41"
              /></a>
              <a href="https://1stdigital.com/" target="_blank">
                <img
                  src="../assets/images/icons/row42.png"
                  alt=""
                  class="row42"
              /></a>
              <a href="https://www.liquiditytech.com/" target="_blank">
                <img
                  src="../assets/images/icons/row43.png"
                  alt=""
                  class="row43"
              /></a>
              <a href="https://alchemypay.org/" target="_blank">
                <img
                  src="../assets/images/icons/row44.png"
                  alt=""
                  class="row44"
              /></a>
            </div>
            <div class="row">
              <div class="l"></div>
              <a href="https://dcbot.ai/ " target="_blank">
                <img
                  src="../assets/images/icons/row51.png"
                  alt=""
                  class="row51"
              /></a>
              <a href="https://bouncebit.io/" target="_blank">
                <img
                  src="../assets/images/icons/row52.png"
                  alt=""
                  class="row52"
              /></a>
            </div>
            <div class="row">
              <div class="l">
                <div>Research Institute and Associations</div>
                <div>研究机构及协会</div>
              </div>
              <a href="https://singaporeblockchain.org/" target="_blank">
                <img
                  src="../assets/images/icons/row61.png"
                  alt=""
                  class="row61"
              /></a>
              <a href="https://singaporefintech.org/" target="_blank">
                <img
                  src="../assets/images/icons/row62.png"
                  alt=""
                  class="row62"
              /></a>
              <a href="https://www.nus.edu.sg/" target="_blank">
                <img
                  src="../assets/images/icons/row63.png"
                  alt=""
                  class="row63"
              /></a>
              <a href="https://allinweb3.io/" target="_blank">
                <img
                  src="../assets/images/icons/row64.png"
                  alt=""
                  class="row64"
              /></a>
              <a
                href="https://www.alibabacloud.com/en/innovation-accelerator/index?_p_lc=4"
                target="_blank"
              >
                <img
                  src="../assets/images/icons/row65.png"
                  alt=""
                  class="row65"
              /></a>
            </div>
            <div class="row">
              <div class="l"></div>
              <a href="https://www.anext.com.sg/" target="_blank">
                <img
                  src="../assets/images/icons/row71.png"
                  alt=""
                  class="row71"
              /></a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bannerbg">
      <div class="swiperbanxin">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <div class="swiper1">
                <div style="flex: 1; margin-top: 60px">
                  <h2 style="margin-bottom: 50px">Vision</h2>
                  <p style="margin-bottom: 50px" class="lh-30">
                    The Asia-Pacific region has a storied history of vibrant
                    innovation, high growth, and groundbreaking advancements in
                    technology, finance, and sustainability. Leveraging this
                    legacy, our mission is to become the world's leading
                    platform for tokenizing real-world assets while leaving a
                    positive mark across the Asia-Pacific and beyond. In
                    partnership with industry pioneers, we aspire to establish
                    universally trusted benchmarks in innovation, compliance,
                    and transparency.
                  </p>

                  <p style="margin-bottom: 50px" class="lh-30">
                    亚太地区一直是全球最充满活力、高增长和创新的地区之一，不断推动金融、科技与绿色的结合。我们发起于亚太，深耕于亚太，并努力成为全球领先的RWA平台。与行业领袖合作，在创新、合规和透明度方面设定可信赖的全球标准。
                  </p>
                </div>
                <div>
                  <div
                    style="
                      text-align: right;
                      margin-bottom: 50px;
                      margin-right: 2px;
                    "
                  >
                    <a
                      :href="
                        href +
                        'file/Asia Pacific Real-World-Asset (RWA) Tokenization Platform.pdf'
                      "
                      target="_blank"
                    >
                      <img
                        class="cursor"
                        src="../assets/images/index/readdocu.png"
                        alt=""
                      />
                    </a>
                  </div>
                  <div>
                    <img
                      src="../assets/images/index/qiu.jpg"
                      alt=""
                      class="qiu"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="swiper1">
                <div style="flex: 1; margin-top: 60px">
                  <h2 style="margin-bottom: 50px">Challenges</h2>
                  <div style="margin-bottom: 50px" class="lh-30">
                    <div>
                      <span class="dian">●</span> Lack of standardized
                      technology framework
                    </div>
                    <div>
                      <span class="dian">●</span> Lack of education in
                      blockchain technology to help avoid potential scams for
                      investors
                    </div>
                    <div>
                      <span class="dian">●</span> Lack of first-hand cases in
                      real-world business for execution
                    </div>
                    <div>
                      <span class="dian">●</span> Fragmentation in Asia Pacific:
                      Extraterritoriality of national/regional regulations
                    </div>
                  </div>
                  <div style="margin-bottom: 50px" class="lh-30">
                    <div><span class="dian">●</span> 缺乏标准化的技术框架</div>
                    <div>
                      <span class="dian">●</span> 缺乏区块链⾏业教育普及
                    </div>
                    <div>
                      <span class="dian">●</span> 缺乏现实的商业可⾏案例
                    </div>
                    <div><span class="dian">●</span> 国家/地区法规尚未统⼀</div>
                  </div>
                </div>
                <div>
                  <div
                    style="
                      text-align: right;
                      margin-bottom: 50px;
                      margin-right: 2px;
                    "
                  >
                    <a
                      :href="
                        href +
                        'file/Asia Pacific Real-World-Asset (RWA) Tokenization Platform.pdf'
                      "
                      target="_blank"
                    >
                      <img
                        src="../assets/images/index/readdocu.png"
                        alt=""
                        class="cursor"
                      />
                    </a>
                  </div>
                  <div>
                    <img
                      src="../assets/images/index/bannerR2.png"
                      alt=""
                      class="qiu"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="swiper1">
                <div style="flex: 1; margin-top: 60px">
                  <h2 style="margin-bottom: 30px">Mission</h2>
                  <p style="margin-bottom: 30px" class="lh-30">
                    Our mission is to democratize asset ownership and to empower
                    every individual through unprecedented financial technology
                    and inclusion.
                  </p>
                  <div class="row">
                    <span> Facilitate </span>
                    seamless information exchange among stakeholders.
                  </div>
                  <div class="row">
                    <span> Standardize </span>
                    tokenization procedures and protocols across multiple asset
                    classes.
                  </div>
                  <div class="row">
                    <span> Build </span>
                    a robust and secure blockchain technology infrastructure.
                  </div>
                  <div class="row">
                    <span> Drive </span>
                    real-world implementation and business case development.
                  </div>
                  <div class="row">
                    <span> Promote </span>
                    legal compliance and ethical standards.
                  </div>

                  <p style="margin-bottom: 30px" class="lh-30">
                    我们的使命不仅仅是普惠资产所有权，更是通过领先的科技⾦融来赋能每个⼈。我们将通过定期举办
                    开放性的论坛以交流观点，建⽴⾏业范围内的RWA标准，与传统⾦融机构合作以建⽴技术基础设施，
                    并开创现实世界商业案例实施来实现这⼀⽬标。
                  </p>
                  <div class="row">
                    <span>促进</span>

                    各利益相关⽅之间的⽆缝信息交流
                  </div>
                  <div class="row">
                    <span>建立</span>

                    跨资产类别的通证化标准程序和协议
                  </div>
                  <div class="row">
                    <span>构建</span>
                    可靠和安全的区块链技术基础设施
                  </div>
                  <div class="row">
                    <span>推动</span>
                    实物资产通证化的实际应⽤和商业案例
                  </div>
                  <div class="row">
                    <span>促进</span>
                    法律合规和道德标准的完善
                  </div>
                </div>
                <div>
                  <div
                    style="
                      text-align: right;
                      margin-bottom: 50px;
                      margin-right: 2px;
                    "
                  >
                    <a
                      :href="
                        href +
                        'file/Asia Pacific Real-World-Asset (RWA) Tokenization Platform.pdf'
                      "
                      target="_blank"
                    >
                      <img
                        src="../assets/images/index/readdocu.png"
                        alt=""
                        class="cursor"
                      />
                    </a>
                  </div>
                  <div>
                    <img
                      src="../assets/images/index/bannerR3.png"
                      alt=""
                      class="qiu"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="swiper-pagination swiper-pagination-bullets"></div>
        </div>
      </div>
    </div>
    <div class="banxin">
      <div class="core">
        <h3>Core <span>Objectives</span></h3>
        <div class="infoList">
          <div class="item">
            <div class="icon">
              <img src="../assets/images/icons/core1.png" alt="" />
            </div>
            <div class="title">
              <div>INFORMATION</div>
              <div>信息</div>
            </div>
            <p>Facilitate seamless information exchange among stakeholders.</p>
            <p>促进各利益相关方之间的无缝信息交流。</p>
          </div>
          <div class="item">
            <div class="icon">
              <img src="../assets/images/icons/core2.png" alt="" />
            </div>
            <div class="title">
              <div>STANDARDIZATION</div>
              <div>标准</div>
            </div>
            <p>
              Standardize tokenization procedures and protocols across multiple
              asset classes.
            </p>
            <p>建立跨资产类别的通证化标准程序和协议。</p>
          </div>
          <div class="item">
            <div class="icon">
              <img src="../assets/images/icons/core3.png" alt="" />
            </div>
            <div class="title">
              <div>TECHNOLOGY</div>
              <div>技术</div>
            </div>
            <p>
              Build a robust and secure blockchain technology infrastructure.
            </p>
            <p>构建可靠和安全的区块链技术基础设施。</p>
          </div>
          <div class="item">
            <div class="icon">
              <img src="../assets/images/icons/core4.png" alt="" />
            </div>
            <div class="title">
              <div>CASE</div>
              <div>案例</div>
            </div>
            <p>
              Drive real-world implementation and business case development.
            </p>
            <p>推动实物资产通证化的实际应用和商业案例。</p>
          </div>
          <div class="item">
            <div class="icon">
              <img src="../assets/images/icons/core5.png" alt="" />
            </div>
            <div class="title">
              <div>COMPLIANCE</div>
              <div>合规</div>
            </div>
            <p>
              Promote legal compliance, ethical standards to foster a more
              sustainable global ecosystem.
            </p>
            <p>促进法律合规和道德标准的完善以促成有机的全球生态体系。</p>
          </div>
        </div>
      </div>
    </div>
    <div style="background: #f9f9f9">
      <div class="framework">
        <div class="left">
          <div class="imgBox">
            <div
              class="imgItem cursor"
              @click="
                readPdf(
                  'https://www.mas.gov.sg/-/media/mas/sectors/guidance/guide-to-digital-token-offerings-26-may-2020.pdf'
                )
              "
            >
              <img src="../assets/images/index/work1.png" alt="" />
              <img
                class="ctbtn"
                src="../assets/images/index/sgpbtn.png"
                alt=""
              />
            </div>
            <div
              class="imgItem cursor"
              @click="
                readPdf(
                  'https://www.hkma.gov.hk/media/eng/doc/key-information/press-release/2023/20230824e3a1.pdf'
                )
              "
            >
              <img src="../assets/images/index/work2.png" alt="" />
              <img
                class="ctbtn"
                src="../assets/images/index/hkbtn.png"
                alt=""
              />
            </div>
            <div
              class="imgItem cursor"
              @click="
                readPdf(
                  'https://www.fsa.go.jp/singi/digital/siryou/20230606/2jstoa.pdf'
                )
              "
            >
              <img src="../assets/images/index/work3.png" alt="" />
              <img
                class="ctbtn"
                src="../assets/images/index/jpbtn.png"
                alt=""
              />
            </div>
            <div
              class="imgItem cursor"
              @click="
                readPdf(
                  'https://www.bakermckenzie.com/-/media/files/insight/guides/2023/guide-to-regulation-on-cryptocurrency-and-digital-token.pdf?sc_lang=en&hash=964D4E24866C240B691FC95B484B5265'
                )
              "
            >
              <img src="../assets/images/index/work4.png" alt="" />
              <img
                class="ctbtn"
                src="../assets/images/index/thbtn.png"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="right">
          <h2 style="text-align: right">Regulation Framework</h2>
          <p style="margin-bottom: 20px">
            Various forecasts of the size of real-world asset tokenization to be
            multi-triliion-dollars by 2030. Within Asia-Pacific, the prethere is
            an increasing focus by regulators on the digitalization of token.
            There are some countries that have already started establishing
            policies and rules to regulate the market. Although different
            countries have various regulation frameworks, this action
            demonstrates the positive perspective of tokenization in general.
          </p>
          <p>
            据各种预测，到 2030
            年，真实世界资产代币化的规模将达到数万亿美元。在亚太地区，监管机构越来越重视代币的数字化。即使不同国家有不同的监管框架，但这一行动总体上表明了对代币化的积极态度。
          </p>
        </div>
      </div>
    </div>

    <div @click="hrefEvent">
      <img
        class="cursor"
        src="../assets/images/index/event.png"
        alt=""
        width="100%"
      />
    </div>
    <div class="calendar">
      <div class="blogTitle">
        <div class="blog">Blog</div>
        <div>
          <img
            @click="$router.push('/aboutus/blog')"
            class="cursor"
            src="../assets/images/index/viewmore.png"
            alt=""
          />
        </div>
      </div>
      <div class="cards">
        <div
          class="item cursor"
          v-for="item in list"
          :key="item.guid"
          @click="hrefLink(item)"
        >
          <img :src="item.article_image" alt="" class="article_image" />
          <div class="article_title">
            {{ item.article_title }}
          </div>

          <div class="article_time">
            <i class="el-icon-date" style="color: #afb1b7"></i>
            {{ $dayjs(item.article_publish_time * 1000).format("ll") }}
          </div>
        </div>
      </div>
    </div>
    <div class="join">
      <div>Join AP®TP</div>
      <img
        class="cursor"
        src="../assets/images/index/joinus.png"
        alt=""
        @click="$router.push('/form')"
      />
    </div>
    <Bottom></Bottom>
  </div>
</template>

<script>
import Header from '@/components/Header'
import Bottom from '@/components/Bottom'
import { getBanners } from '@/api/home'
import { getAboutList } from '@/api/about'

export default {
  components: { Header, Bottom },
  data () {
    return {
      banners: [],
      list: [],
      href: window.location.href.split('#')[0],
    }
  },
  created () {
    this.getBlog()
  },
  async mounted () {
    let mySwiper = new Swiper('.swiper-container', {
      pagination: {
        // 按钮
        el: '.swiper-pagination',
        clickable: true, // 分页导航是否可点击
        loop: true,
      },
      loop: true, // 环路(无缝滚动)
      speed: 600, // 切换速度
      autoplay: {
        // 自动切换
        delay: 4000, // 自动切换的时间间隔
        stopOnLastSlide: false, // 如果设置为true,当切换到最后一个slide时停止自动切换(loop模式下无效)
        disableOnInteraction: false, // 用户操作swiper之后,是否禁止autoplay.默认为true:停止
      },
    })

    await this.init()
    let mySwiper2 = new Swiper('.swiper-container2', {
      loop: false, // 环路(无缝滚动)
      speed: 1000, // 切换速度
      on: {
        tap: (i) => {
          if (this.banners[i.realIndex].categ == 2) {
            window.open(this.banners[i.realIndex].link_url)
          } else {
            this.$router.push(this.banners[i.realIndex].link_url)
          }
          //   //wx.miniProgram.navigateTo({url: '/pages/tree1/tree1?id='+that.currentId})
        },
      },
    })
  },

  methods: {
    async init () {
      const res = await getBanners()
      this.banners = res.data
      console.log(res)
    },
    async getBlog () {
      const res = await getAboutList(' blogs', 1, 3)
      console.log(res)
      this.list = res.data.articles
    },
    hrefEvent () {
      this.$router.push('/event')
      // console.log(window.location.origin)
    },
    hrefLink (item) {
      this.$router.push(`/aboutus/blog/Detail/${item.guid}`)
    },
    readPdf (href) {
      window.open(href)
    },
    link (item) {
      console.log(item)
      // if (item.categ == 2) {
      //   window.open(item.link_url)
      // } else {
      //   this.$router.push(item.link_url)
      // }
    },
  },
  watch: {},
}
</script>

<style lang="less" scoped>
@import "../assets/yk_global.css";

.bg {
  padding-bottom: 406px;
  position: relative;
  min-width: 1361px;
  width: 100%;
}
.swiper-container {
  .swiper-wrapper {
    height: 100%;
  }
  .swiper-slide {
    background-position: center;
    background-size: cover;
    height: 100%;
  }
}

.swiper-container2 {
  .swiper-wrapper {
    height: 100%;
  }
  .swiper-slide {
    background-position: center;
    background-size: cover;
    height: 100%;
  }
}

.bannerbg {
  height: 1018px;
  box-sizing: border-box;
  background: url("../assets/images/index/bannerBg.png") no-repeat;
  background-size: 100% 100%;
  .swiperbanxin {
    width: 80%;
    margin: 0 auto;
    padding-top: 72px;
  }
}
.swiper-pagination-bullets {
  width: 100px;
  top: 0;
  left: 0;
}
::v-deep .swiper-pagination-bullet-active {
  background: #227a6d !important;
}
.swiper1 {
  padding: 0 10px;
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  h2 {
    font-size: 48px;
    font-family: HONOR Sans-Bold, HONOR Sans;
    font-weight: bold;
  }
}
.qiu {
  width: 522px;
  height: auto;
  margin-left: 100px;
}
.btn {
  display: block;
  width: 551px;
  margin: 50px auto;
}
.row {
  margin-bottom: 20px;
  line-height: 40px;
  font-size: 17px;
  span {
    font-size: 22px;
    font-family: HONOR Sans-Bold, HONOR Sans;
    font-weight: 900;
  }
}
.framework {
  width: 80%;
  margin: 0 auto;
  padding: 169px;
  display: flex;
  justify-content: space-between;

  .right {
    margin-left: 100px;
    width: 620px;
    text-align: right;
  }

  h2 {
    font-size: 48px;
    font-family: HONOR Sans-Bold, HONOR Sans;
    font-weight: bold;
    margin-bottom: 50px;
  }
  p {
    font-size: 20px;
    line-height: 32px;
  }
}
.workimglist {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  .workitem {
    position: relative;
    img {
      width: 287px;
    }
    .ctText {
      font-size: 20px;
      font-weight: 400;
      color: #000000;
      width: 250px;
      height: 80px;
      line-height: 80px;
      text-align: center;
      border: 1px solid #707070;
      border-radius: 10px;
      background: #fff;
      position: absolute;
      bottom: -40px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
.calendar {
  width: 80%;
  margin: 0 auto;
  .blogTitle {
    display: flex;
    height: 214px;
    justify-content: space-between;
    align-items: center;
    .blog {
      font-size: 48px;
      font-weight: bold;
      color: #000000;
    }
  }
}
.join {
  box-sizing: border-box;
  text-align: center;
  padding: 70px 0;
  height: 251px;
  background: #f9f9f9;
  font-size: 48px;
  font-weight: bold;
  color: #000000;
  img {
    margin-top: 20px;
    width: 235px;
  }
}
.banxin {
  width: 80%;
  margin: 0 auto;
}
.cards {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;

  .item {
    position: relative;
    margin-bottom: 25px;
    border-radius: 4px;
    border: 1px solid #a5a5a5;
    box-sizing: border-box;
    width: 441px;
    height: 587px;
    padding: 23px 27px;
    .article_image {
      width: 100%;
      height: 258px;
    }
    .article_title {
      font-size: 26px;
      font-weight: 400;
      color: #000000;
      line-height: 40px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
    }
    .article_time {
      position: absolute;
      bottom: 35px;
      left: 27px;
    }
  }
}
.dian {
  font-size: 12px;
}
.left {
  flex: 1;
}
.imgBox {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.imgItem {
  width: 287px;
  position: relative;
  margin-bottom: 100px;
  img {
    width: 100%;
  }
  .ctbtn {
    width: 251px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
  }
}
.lh-30 {
  line-height: 30px;
}
.whoarewe {
  box-sizing: border-box;
  padding: 95px 0;
  display: flex;
  .l {
    position: relative;
    width: 552px;
    h3 {
      font-size: 48px;
      font-weight: bold;
      margin-bottom: 20px;
    }
    .being {
      width: 495px;
      p {
        line-height: 32px;
        font-size: 20px;
        margin-bottom: 20px;
      }
    }
    .whoimg {
      width: 552px;
      position: absolute;
      top: 280px;
    }
  }
  .r {
    display: flex;

    .label {
      // width: 304px;
      .row {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        font-size: 16px;
        .l {
          font-size: 16px;
          line-height: 25px;
          margin-right: 25px;
          width: 254px;
          text-align: right;
        }
        img {
          vertical-align: middle;
          margin-right: 20px;
        }
      }
    }
  }
}
.core {
  padding: 152px 0;
  h3 {
    margin-bottom: 97px;
    font-size: 48px;
    font-weight: bold;
    span {
      color: #048777;
    }
  }
  .infoList {
    display: flex;
    .item {
      margin-right: 43px;
      flex: 1;
      img {
        width: 87px;
        margin-bottom: 41px;
      }
      .title {
        font-size: 22px;
        font-weight: bold;
        color: #000000;
        line-height: 32px;
        margin-bottom: 34px;
      }
      p {
        font-size: 20px;
        font-weight: 400;
        color: #000000;
        line-height: 32px;
        margin-bottom: 20px;
      }
    }
  }
}

.row11 {
  margin-right: 30px !important;
  height: 21px;
}
.row12 {
  margin-right: 35px !important;
  height: 22px;
}
.row13 {
  height: 29px;
}

.row21 {
  margin-right: 37px !important;
  height: 24px;
}
.row22 {
  height: 21px;
}
.row31 {
  margin-right: 45px !important;
  height: 21px;
}
.row32 {
  margin-right: 22px !important;
  height: 24px;
}
.row33 {
  height: 21px;
}
.row34 {
  height: 30px;
}
.row41 {
  height: 14px;
}
.row42 {
  height: 29px;
}
.row43 {
  margin-right: 28px !important;
  height: 24px;
}
.row44 {
  height: 40px;
}
.row51 {
  margin-right: 35px !important;

  height: 50px;
}
.row52 {
  height: 22px;
}
.row61 {
  margin-right: 45px !important;

  height: 30px;
}
.row62 {
  margin-right: 35px !important;
  height: 21px;
}
.row63 {
  height: 34px;
}
.row64 {
  height: 43px;
}
.row65 {
  height: 43px;
}
.row71 {
  height: 10px;
}
</style>
